<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.917"
    height="35.333"
    viewBox="0 0 30.917 35.333"
  >
    <path
      id="trash-can-regular"
      d="M11.766,3.561l-1.311,1.96H20.462L19.15,3.561a.563.563,0,0,0-.462-.248H12.222a.547.547,0,0,0-.462.248ZM21.911,1.725l2.533,3.8h4.817a1.656,1.656,0,0,1,0,3.313h-.552V29.813a5.519,5.519,0,0,1-5.521,5.521H7.729a5.519,5.519,0,0,1-5.521-5.521V8.833H1.656a1.656,1.656,0,0,1,0-3.313H6.473l2.533-3.8A3.88,3.88,0,0,1,12.222,0h6.466A3.88,3.88,0,0,1,21.9,1.718ZM5.521,8.833V29.813a2.206,2.206,0,0,0,2.208,2.208H23.188A2.206,2.206,0,0,0,25.4,29.813V8.833Zm5.521,4.417V27.6a1.1,1.1,0,1,1-2.208,0V13.25a1.1,1.1,0,1,1,2.208,0Zm5.521,0V27.6a1.1,1.1,0,0,1-2.208,0V13.25a1.1,1.1,0,0,1,2.208,0Zm5.521,0V27.6a1.1,1.1,0,1,1-2.208,0V13.25a1.1,1.1,0,1,1,2.208,0Z"
      fill="#b5b5b5"
    />
  </svg>
</template>
