/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'
import { customSvgs } from '@/components/icons/customSvgs'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  display: {
    mobileBreakpoint: 'md',
    thresholds: {
      xs: 0,
      sm: 340,
      md: 749,
      lg: 1400,
      xl: 1920,
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#ffffff',
          black_deep1: '#000823',
          deepnavy: '#111C58',
          blue_pastel1: '#3F53B2',
          blue_pastel2: '#4F63C2',
          grey_deep1: '#A8A8A8',
          grey_deep2: '#A5A5A5',
          grey_deep3: '#A4A4A4',
          grey_deep4: '#E6E6E6',
          grey_deep5: '#D6D6D6',
          grey_deep6: '#F0F0F0',
          grey_deep7: '#F2F2F2',
          grey_other1: '#B7B7B7',
          grey_other2: '#707070',
          grey_other3: '#B5B5B5',
          grey_other4: '#8B8B8B',
          grey_other5: '#9E9E9E',
          grey_other6: '#EBEBEB',
          grey_other7: '#B9B9B9',
          highlight_yellow: '#FFFF66',
          highlight_orange: '#FF9900',
          highlight_pink: '#E72D5C',
          highlight_brown: '#C5874A',
          highlight_green: '#32586D',
          highlight_brown2: '#855950',
          green_dark1: '#AFB8A7',
          yellow_dark1: '#B8B89C',
          yellow_dark2: '#84837F',
          brown_light1: '#DBB380',
          brown_light2: '#BF9D84',
          purple_light1: '#B2A7B8',
          admin_nav_bar: '#EAEFF1',
          admin_nav_bar2: '#CFCFCD',
          admin_btn1: '#5c708d',
          admin_red_btn: '#ED4A56',
          admin_file_btn: '#5b6f8a',
          program_1: '#E2E2E2',
          program_2: '#E2EFDA',
          program_3: '#F5DC8E',
          program_4: '#FFE7FF',
          program_5: '#DAE0FE',
          program_6: '#A5C3FF',
          program_7: '#E8EAF3',
          program_8: '#FFF2CC',
          program_9: '#D6C0FF',
          diamond: '#E72D5C',
          platinum: '#839DB1',
          gold: '#CCBD87',
          silver: '#CBCBCB',
          bronze: '#B8B595',
          garnet: '#BE9292',
          picpart: '#FFF0C9',
          guideline_table_1: '#156082',
          guideline_table_1_head: '#CCD2D8',
          guideline_table_1_body: '#E7EAED',
          guideline_table_2: '#A02B93',
          guideline_table_2_head: '#DFCDDC',
          guideline_table_2_body: '#F0E8EE',
          streaming_red: '#FF0000',
        },
      },
    },
  },
  icons: {
    sets: {
      custom: customSvgs,
    },
  },
})
