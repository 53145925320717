<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74.351"
    height="60.409"
    viewBox="0 0 74.351 60.409"
  >
    <path
      id="how_to_reg_FILL0_wght200_GRAD200_opsz48"
      d="M102.692-707.621v-7.266a8.41,8.41,0,0,1,1.588-5.294,11.826,11.826,0,0,1,4.21-3.349,60.54,60.54,0,0,1,11.812-4.39,51.316,51.316,0,0,1,11.418-1.128q3.028,0,5.469.193a36.334,36.334,0,0,1,4.8.7l-3.732,3.807q-1.369-.1-3.065-.246t-3.536-.148a48.439,48.439,0,0,0-10.24,1,51.766,51.766,0,0,0-10.854,3.959,5.877,5.877,0,0,0-2.875,2.163,5.449,5.449,0,0,0-.682,2.734v2.959h26.537l4.7,4.307Zm50.751,3.414-11.982-11.982,3.05-3.05,8.932,8.833,20.452-20.452,3.149,3.05Zm-21.761-35.537a12.089,12.089,0,0,1-8.911-3.476,12.057,12.057,0,0,1-3.488-8.922,12.153,12.153,0,0,1,3.5-8.96,12.04,12.04,0,0,1,8.9-3.514,12.148,12.148,0,0,1,8.932,3.514,12.094,12.094,0,0,1,3.537,8.96,11.989,11.989,0,0,1-3.525,8.922A12.215,12.215,0,0,1,131.682-739.744ZM133.536-711.928Zm-1.817-32.123A7.858,7.858,0,0,0,137.5-746.4a7.858,7.858,0,0,0,2.346-5.783,7.858,7.858,0,0,0-2.346-5.783,7.918,7.918,0,0,0-5.832-2.346,7.755,7.755,0,0,0-5.783,2.346,7.945,7.945,0,0,0-2.3,5.783A7.93,7.93,0,0,0,125.9-746.4,7.822,7.822,0,0,0,131.719-744.051ZM131.719-752.18Z"
      transform="translate(-102.692 764.616)"
      fill="#e72d5c"
    />
  </svg>
</template>
