<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 595.28 565.03">
    <g>
      <g id="_레이어_1" data-name="레이어_1">
        <g>
          <path
            fill="#fff"
            d="M345.28,356.18l14.16,14.16v-138.75c0-19.78,16.09-35.87,35.87-35.87s35.87,16.09,35.87,35.87v38.43c5.35-3.15,11.56-4.95,18.11-4.95h.38c16.31,0,30.11,10.95,34.45,25.88,5.81-3.84,12.62-5.9,19.72-5.9h.38c10.62,0,20.17,4.64,26.74,12V89.86c0-40.48-32.82-73.3-73.32-73.3H91.11c-40.49,0-73.3,32.82-73.3,73.3v211.73c0,40.48,32.81,73.3,73.3,73.3h193.63c1.29-6.91,4.59-13.48,9.82-18.71,13.98-13.98,36.74-13.98,50.72,0ZM241.28,275.64c-11.63,7.27-26.72-1.1-26.72-14.81v-130.2c0-13.73,15.08-22.08,26.72-14.81l104.17,65.1c10.94,6.84,10.94,22.78,0,29.63l-104.17,65.1Z"
          />
          <path
            fill="#fff"
            d="M571.35,329.11c-3.46-3.46-8.05-5.36-12.95-5.36-9.88,0-18.07,8.01-18.3,17.87,0,1.88,0,40.26,0,41.52v.03h-17.56v-62.24c0-10.09-8.21-18.31-18.31-18.31h-.38c-9.95,0-17.97,7.76-18.3,17.69,0,2.32,0,42.85-.01,49.09v.7h-17.56v-69.16c0-10.09-8.21-18.31-18.31-18.31h-.38c-8.95,0-16.62,6.55-18.06,15.32,0,3.2-.02,19.29-.03,33.62-.01,16.81-.01,24.55-.03,28.21l-.06,2.48-17.5.04v-130.71c0-10.09-8.21-18.31-18.31-18.31s-18.31,8.21-18.31,18.31v181.14l-37.83-37.83-6.3-6.3c-7.14-7.14-18.75-7.14-25.89,0-1.83,1.83-3.19,3.99-4.08,6.3-2.22,5.73-1.51,12.42,2.33,17.57l.08.1c3.14,4.21,6.39,8.57,9.45,13.06l70.62,103.61c8.31,12.19,12.7,26.44,12.7,41.19v6.65h150.97v-24.74c0-7.22,1.31-14.29,3.88-21.03l8.53-24.34c4.2-11.96,8.92-23.69,13.01-33.75,1.48-3.64,2.23-7.47,2.23-11.4v-99.76c0-4.89-1.9-9.49-5.36-12.94Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
