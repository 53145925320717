import { h } from 'vue'
import icon_paperclip from './icon_paperclip.vue'
import icon_calenday from './icon_calenday.vue'
import icon_bookmark from './icon_bookmark.vue'
import icon_book from './icon_book.vue'
import icon_images from './icon_images.vue'
import icon_housechimney from './icon_housechimney.vue'
import icon_snow from './icon_snow.vue'
import icon_trashcan from './icon_trashcan.vue'
import icon_cloud from './icon_cloud.vue'
import icon_clock from './icon_clock.vue'
import icon_international_orgnization from './icon_international_orgnization.vue'
import icon_money from './icon_money.vue'
import icon_powersocket from './icon_powersocket.vue'
import icon_document from './icon_document.vue'
import icon_flag_korea from './icon_flag_korea.vue'
import icon_burgur_menu from './icon_burgur_menu.vue'
import icon_account from './icon_account.vue'
import icon_menu_triangle from './icon_menu_triangle.vue'
import icon_register from './icon_register.vue'
import icon_newsletter from './icon_newsletter.vue'
import icon_top_side from './icon_top_side.vue'
import icon_korea_flag from './icon_korea_flag.vue'
import icon_play_streaming from './icon_play_streaming.vue'

const customSvgIcons = {
  icon_paperclip,
  icon_calenday,
  icon_bookmark,
  icon_book,
  icon_images,
  icon_housechimney,
  icon_snow,
  icon_trashcan,
  icon_cloud,
  icon_clock,
  icon_international_orgnization,
  icon_money,
  icon_powersocket,
  icon_document,
  icon_flag_korea,
  icon_burgur_menu,
  icon_account,
  icon_menu_triangle,
  icon_register,
  icon_newsletter,
  icon_top_side,
  icon_korea_flag,
  icon_play_streaming,
}

const customSvgs = {
  component: (props) => h(customSvgIcons[props.icon]),
}

export { customSvgs }
