<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.861"
    height="12.667"
    viewBox="0 0 10.861 12.667"
  >
    <path
      id="asterisk-solid"
      d="M5.358,32a.9.9,0,0,1,.9.9v3.831l3.153-1.892a.9.9,0,1,1,.93,1.552L7.117,38.333l3.232,1.94a.9.9,0,0,1-.93,1.552L6.263,39.931v3.831a.9.9,0,1,1-1.81,0V39.931L1.3,41.822a.9.9,0,0,1-.93-1.552L3.6,38.333.368,36.394a.9.9,0,0,1,.93-1.552l3.155,1.894V32.9A.9.9,0,0,1,5.358,32Z"
      transform="translate(0.072 -32)"
    />
  </svg>
</template>
