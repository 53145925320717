<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="7.001" height="11.669" viewBox="0 0 7.001 11.669">
    <path
      id="caret-right-solid"
      d="M70.559,102.581a1.168,1.168,0,0,0,0-1.651l-4.665-4.665a1.169,1.169,0,0,0-1.994.827v9.331a1.169,1.169,0,0,0,1.994.827l4.665-4.665Z"
      transform="translate(-63.9 -95.922)"
      fill="#040f2d"
    />
  </svg>
</template>
