<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.598"
    height="20.112"
    viewBox="0 0 17.598 20.112"
  >
    <path
      id="user-solid_1_"
      data-name="user-solid (1)"
      d="M8.8,10.056A5.028,5.028,0,1,0,3.771,5.028,5.028,5.028,0,0,0,8.8,10.056ZM7,11.942a7,7,0,0,0-7,7,1.167,1.167,0,0,0,1.167,1.167H16.432A1.167,1.167,0,0,0,17.6,18.946a7,7,0,0,0-7-7Z"
      fill="#fff"
    />
  </svg>
</template>
