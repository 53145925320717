<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="59.391"
    height="59.391"
    viewBox="0 0 59.391 59.391"
  >
    <path
      id="clock-solid"
      d="M29.7,0A29.7,29.7,0,1,1,0,29.7,29.7,29.7,0,0,1,29.7,0ZM26.911,13.92V29.7a2.8,2.8,0,0,0,1.241,2.32l11.136,7.424a2.786,2.786,0,0,0,3.086-4.64l-9.895-6.589V13.92a2.784,2.784,0,0,0-5.568,0Z"
      fill="#111c58"
    />
  </svg>
</template>
