import gql from 'graphql-tag'

export const register = gql`
  mutation register(
    $username: String!
    $email: String!
    $password: String!
    $phone: String
    $country: String
    $address: String
    $mobilePhone: String!
    $fax: String
    $confirmed: Boolean
    $visa: Boolean
    $title: String!
    $degree: String!
    $affiliation: String!
    $department: String!
    $name_kr: String
    $affiliation_kr: String
    $givenName: String
    $familyName: String
    $howFind: String
    $viaEmail: Boolean
    $receiveInfo: Boolean
    $invited: Boolean
    $occupation: String
    $medi_license: String
    $occupationOther: String
    $visaGivenName: String
    $visaFamilyName: String
    $visaCountry: String
    $visaPassportNumber: String
    $visaBirth: String
    $visaIssue: String
    $visaExpire: String
  ) {
    register(
      input: {
        username: $username
        email: $email
        password: $password
        phone: $phone
        mobilePhone: $mobilePhone
        confirmed: $confirmed
        visa: $visa
        title: $title
        country: $country
        address: $address
        degree: $degree
        givenName: $givenName
        familyName: $familyName
        name_kr: $name_kr
        affiliation: $affiliation
        affiliation_kr: $affiliation_kr
        department: $department
        fax: $fax
        howFind: $howFind
        receiveInfo: $receiveInfo
        viaEmail: $viaEmail
        invited: $invited
        delete: false
        blocked: false
        occupation: $occupation
        medi_license: $medi_license
        occupationOther: $occupationOther
        visaGivenName: $visaGivenName
        visaFamilyName: $visaFamilyName
        visaCountry: $visaCountry
        visaPassportNumber: $visaPassportNumber
        visaBirth: $visaBirth
        visaIssue: $visaIssue
        visaExpire: $visaExpire
      }
    ) {
      jwt
      user {
        id
      }
    }
  }
`

export const login = gql`
  mutation login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
        username
        delete
        country
        role {
          name
        }
      }
    }
  }
`

export const updateUser = gql`
  mutation updateUser(
    $id: ID!
    $password: String
    $affiliation: String
    $department: String
    $title: String
    $degree: String
    $address: String
    $country: String
    $phone: String
    $mobilePhone: String
    $fax: String
    $visa: Boolean
    $viaEmail: Boolean
    $givenName: String
    $familyName: String
    $invited: Boolean
    $name_kr: String
    $affiliation_kr: String
    $occupation: String
    $medi_license: String
    $occupationOther: String
    $visaGivenName: String
    $visaFamilyName: String
    $visaCountry: String
    $visaPassportNumber: String
    $visaBirth: String
    $visaIssue: String
    $visaExpire: String
  ) {
    updateUser(
      input: {
        where: { id: $id }
        data: {
          familyName: $familyName
          givenName: $givenName
          invited: $invited
          country: $country
          password: $password
          affiliation: $affiliation
          department: $department
          title: $title
          degree: $degree
          address: $address
          phone: $phone
          mobilePhone: $mobilePhone
          fax: $fax
          visa: $visa
          viaEmail: $viaEmail
          name_kr: $name_kr
          affiliation_kr: $affiliation_kr
          occupation: $occupation
          medi_license: $medi_license
          occupationOther: $occupationOther
          visaGivenName: $visaGivenName
          visaFamilyName: $visaFamilyName
          visaCountry: $visaCountry
          visaPassportNumber: $visaPassportNumber
          visaBirth: $visaBirth
          visaIssue: $visaIssue
          visaExpire: $visaExpire
        }
      }
    ) {
      user {
        id
        country
      }
    }
  }
`

export const createAbstract = gql`
  mutation createAbstract(
    $title: String
    $usersId: ID
    $preference: String
    $category: String
    $objectives: String
    $methods: String
    $conclusions: String
    $keywords: String
    $file_img: [ID]
    $author: JSON
    $including: JSON
    $terms: JSON
    $status: ENUM_ABSTRACT_STATUS
    $topic: String
    $history: JSON
    $results: String
  ) {
    createAbstract(
      input: {
        data: {
          title: $title
          users_permissions_user: $usersId
          preference: $preference
          category: $category
          objectives: $objectives
          methods: $methods
          conclusions: $conclusions
          keywords: $keywords
          file_img: $file_img
          author: $author
          including: $including
          terms: $terms
          status: $status
          topic: $topic
          history: $history
          results: $results
          delete: false
        }
      }
    ) {
      abstract {
        id
        users_permissions_user {
          id
        }
      }
    }
  }
`

export const updateAbstract = gql`
  mutation updateAbstract(
    $id: ID!
    $title: String
    $preference: String
    $category: String
    $objectives: String
    $methods: String
    $conclusions: String
    $keywords: String
    $file_img: [ID]
    $author: JSON
    $including: JSON
    $topic: String
    $history: JSON
    $results: String
  ) {
    updateAbstract(
      input: {
        where: { id: $id }
        data: {
          title: $title
          preference: $preference
          category: $category
          objectives: $objectives
          methods: $methods
          conclusions: $conclusions
          keywords: $keywords
          file_img: $file_img
          author: $author
          including: $including
          results: $results
          topic: $topic
          history: $history
        }
      }
    ) {
      abstract {
        id
      }
    }
  }
`

export const createSpeaker = gql`
  mutation createSpeakers(
    $userId: ID
    $file_cv: ID
    $file_photo: ID
    $file_lecture: ID
    $file_presentation: ID
  ) {
    createSpeaker(
      input: {
        data: {
          users_permissions_user: $userId
          file_cv: $file_cv
          file_photo: $file_photo
          file_lecture: $file_lecture
          file_presentation: $file_presentation
        }
      }
    ) {
      speaker {
        id
      }
    }
  }
`
export const updateSpeaker = gql`
  mutation updateSpeaker(
    $id: ID!
    $file_cv: ID
    $file_photo: ID
    $file_lecture: ID
    $file_presentation: ID
  ) {
    updateSpeaker(
      input: {
        where: { id: $id }
        data: {
          file_cv: $file_cv
          file_photo: $file_photo
          file_lecture: $file_lecture
          file_presentation: $file_presentation
        }
      }
    ) {
      speaker {
        id
      }
    }
  }
`

export const changeUserGroup = gql`
  mutation changeUserGroup($id: [ID], $type: String) {
    changeUserGroup(data: { id: $id, type: $type }) {
      response
    }
  }
`

export const sendNewEmail = gql`
  mutation createSendEmail(
    $type: ENUM_SENDEMAIL_TYPE
    $title: String
    $usermail: String
    $content: String
    $files: [ID]
  ) {
    createSendEmail(
      input: {
        data: { type: $type, title: $title, usermail: $usermail, content: $content, files: $files }
      }
    ) {
      sendEmail {
        id
      }
    }
  }
`

export const changeUserPasswordInFind = gql`
  mutation changeUserPasswordInFind($email: String, $password: String) {
    changeUserPasswordInFind(data: { email: $email, password: $password }) {
      response
    }
  }
`

export const createPopup = gql`
  mutation createPopup(
    $title: String
    $use: Boolean
    $size_width: String
    $size_height: String
    $location: ENUM_POPUP_LOCATION
    $start: DateTime
    $end: DateTime
    $content: String
    $created_name: String
  ) {
    createPopup(
      input: {
        data: {
          use: $use
          title: $title
          size_width: $size_width
          location: $location
          start: $start
          end: $end
          content: $content
          created_name: $created_name
          size_height: $size_height
        }
      }
    ) {
      popup {
        id
      }
    }
  }
`
export const updatePopup = gql`
  mutation updatePopup(
    $id: ID!
    $title: String
    $use: Boolean
    $size_width: String
    $size_height: String
    $location: ENUM_POPUP_LOCATION
    $start: DateTime
    $end: DateTime
    $content: String
    $created_name: String
  ) {
    updatePopup(
      input: {
        where: { id: $id }
        data: {
          use: $use
          title: $title
          size_width: $size_width
          location: $location
          start: $start
          end: $end
          content: $content
          created_name: $created_name
          size_height: $size_height
        }
      }
    ) {
      popup {
        id
      }
    }
  }
`

export const createGroup = gql`
  mutation createGroup($title: String, $type: String, $free: Boolean, $group_user_id: String) {
    createGroup(
      input: {
        data: {
          title: $title
          type: $type
          free: $free
          group_user_id: $group_user_id
          delete: false
        }
      }
    ) {
      group {
        id
      }
    }
  }
`

export const updateGroup = gql`
  mutation updateGroup(
    $id: ID!
    $title: String
    $type: String
    $free: Boolean
    $group_user_id: String
  ) {
    updateGroup(
      input: {
        where: { id: $id }
        data: { title: $title, type: $type, free: $free, group_user_id: $group_user_id }
      }
    ) {
      group {
        id
      }
    }
  }
`

export const deleteMultipleGroups = gql`
  mutation deleteMultipleGroups($id: [ID]) {
    deleteMultipleGroups(data: { id: $id }) {
      response
    }
  }
`
export const deleteMultiplePopup = gql`
  mutation deleteMultiplePopup($id: [ID]) {
    deleteMultiplePopup(data: { id: $id }) {
      response
    }
  }
`
export const createNewsAndNotice = gql`
  mutation createNewsAndNotice(
    $title: String
    $type: ENUM_NEWSANDNOTICE_TYPE
    $content: String
    $gallery_main: ID
    $created_name: String
  ) {
    createNewsAndNotice(
      input: {
        data: {
          title: $title
          gallery_main: $gallery_main
          type: $type
          content: $content
          created_name: $created_name
        }
      }
    ) {
      newsAndNotice {
        id
      }
    }
  }
`
export const updateNewsAndNotice = gql`
  mutation updateNewsAndNotice(
    $id: ID!
    $title: String
    $type: ENUM_NEWSANDNOTICE_TYPE
    $content: String
    $gallery_main: ID
    $viewCount: Int
    $created_name: String
  ) {
    updateNewsAndNotice(
      input: {
        where: { id: $id }
        data: {
          title: $title
          gallery_main: $gallery_main
          type: $type
          content: $content
          viewCount: $viewCount
          created_name: $created_name
        }
      }
    ) {
      newsAndNotice {
        id
      }
    }
  }
`
export const deleteMultipleBbs = gql`
  mutation deleteMultipleBbs($id: [ID]) {
    deleteMultipleBbs(data: { id: $id }) {
      response
    }
  }
`

export const deleteMultipleSpeaker = gql`
  mutation deleteMultipleSpeaker($id: JSON) {
    deleteMultipleSpeaker(data: { id: $id }) {
      response
    }
  }
`
export const deleteMultipleInvitedUser = gql`
  mutation deleteMultipleInvitedUser($id: [ID]) {
    deleteMultipleInvitedUser(data: { id: $id }) {
      response
    }
  }
`
export const createMailTemplate = gql`
  mutation createMailTemplate($title: String, $contents: String, $type: String) {
    createMailTemplate(input: { data: { title: $title, contents: $contents, type: $type } }) {
      mailTemplate {
        id
      }
    }
  }
`
export const updateMailTemplate = gql`
  mutation updateMailTemplate($id: ID!, $title: String, $contents: String, $type: String) {
    updateMailTemplate(
      input: { where: { id: $id }, data: { title: $title, contents: $contents, type: $type } }
    ) {
      mailTemplate {
        id
      }
    }
  }
`
export const deleteMultipleMailTemplates = gql`
  mutation deleteMultipleMailTemplates($id: [ID]) {
    deleteMultipleMailTemplates(data: { id: $id }) {
      response
    }
  }
`
export const deleteMultipleAbstract = gql`
  mutation deleteMultipleAbstract($id: [ID]) {
    deleteMultipleAbstract(data: { id: $id }) {
      response
    }
  }
`

export const createResigtration = gql`
  mutation createRegistrationList(
    $price: String
    $user_id: ID
    $price_type: String
    $price_status: String
    $price_date: DateTime
    $attend: ENUM_REGISTRATIONLIST_ATTEND
    $currency: ENUM_REGISTRATIONLIST_CURRENCY
    $category: String
    $dinnerAttendance: Boolean
    $accompanyingPerson: Int
    $vegetarian: Boolean
    $dietaryNeeds: Boolean
    $alergy: String
  ) {
    createRegistrationList(
      input: {
        data: {
          price: $price
          users_permissions_user: $user_id
          price_type: $price_type
          price_status: $price_status
          price_date: $price_date
          attend: $attend
          currency: $currency
          category: $category
          dinnerAttendance: $dinnerAttendance
          accompanyingPerson: $accompanyingPerson
          vegetarian: $vegetarian
          dietaryNeeds: $dietaryNeeds
          alergy: $alergy
          delete: false
        }
      }
    ) {
      registrationList {
        id
      }
    }
  }
`

export const createPayment = gql`
  mutation createPayment(
    $cash_code: ENUM_PAY_CASH_CODE
    $country: ENUM_PAY_COUNTRY
    $product_name: String!
    $total: Long!
    $ok_url: String
    $noti_url: String!
    $close_url: String!
    $fail_url: String!
    $fail_redirect: String!
    $success_redirect: String!
    $user_id: String!
    $user_name: String!
    $user_email: String!
    $registration_list: ID
    $pay_status: String
    $grade: String
    $statusChecked: Boolean
  ) {
    createPay(
      input: {
        data: {
          cash_code: $cash_code
          country: $country
          product_name: $product_name
          total: $total
          site_url: "kspcongress.org"
          ok_url: $ok_url
          noti_url: $noti_url
          call_type: S
          close_url: $close_url
          fail_url: $fail_url
          fail_redirect: $fail_redirect
          success_redirect: $success_redirect
          user_id: $user_id
          user_name: $user_name
          user_email: $user_email
          registration_list: $registration_list
          pay_status: $pay_status
          grade: $grade
          statusChecked: $statusChecked
        }
      }
    ) {
      pay {
        id
        tid
      }
    }
  }
`

export const updateResigtration = gql`
  mutation updateRegistrationList(
    $id: ID!
    $price: String
    $pay: ID
    $user_id: ID
    $delete: Boolean
    $price_type: String
    $price_status: String
    $price_date: DateTime
    $attend: ENUM_REGISTRATIONLIST_ATTEND
    $currency: ENUM_REGISTRATIONLIST_CURRENCY
    $category: String
    $dinnerAttendance: Boolean
    $accompanyingPerson: Int
    $vegetarian: Boolean
    $dietaryNeeds: Boolean
    $alergy: String
  ) {
    updateRegistrationList(
      input: {
        where: { id: $id }
        data: {
          price: $price
          pay: $pay
          users_permissions_user: $user_id
          price_type: $price_type
          price_status: $price_status
          price_date: $price_date
          attend: $attend
          currency: $currency
          category: $category
          dinnerAttendance: $dinnerAttendance
          accompanyingPerson: $accompanyingPerson
          vegetarian: $vegetarian
          dietaryNeeds: $dietaryNeeds
          alergy: $alergy
          delete: $delete
        }
      }
    ) {
      registrationList {
        id
      }
    }
  }
`

export const createSponsor = gql`
  mutation createSponsor(
    $companyName_kr: String
    $companyName_en: String
    $homepage_en: String
    $bizNumber: String
    $address: String
    $email: String
    $phone: String
    $managerName: String
    $mobilePhone: String
    $grade: ENUM_SPONSOR_GRADE
    $partnership: String
    $businessRegistration: ID
    $sponsor_paper: ID
    $price: Long
    $taxBill: String
    $paymentSchedule: DateTime
    $taxBillSchedule: DateTime
    $taxBillEmail: String
    $manager_memo: String
    $sponsor_pay: String
    $sponsor_pay_status: String
  ) {
    createSponsor(
      input: {
        data: {
          companyName_kr: $companyName_kr
          companyName_en: $companyName_en
          homepage_en: $homepage_en
          bizNumber: $bizNumber
          address: $address
          email: $email
          phone: $phone
          managerName: $managerName
          mobilePhone: $mobilePhone
          grade: $grade
          partnership: $partnership
          businessRegistration: $businessRegistration
          sponsor_paper: $sponsor_paper
          price: $price
          taxBill: $taxBill
          paymentSchedule: $paymentSchedule
          taxBillSchedule: $taxBillSchedule
          taxBillEmail: $taxBillEmail
          manager_memo: $manager_memo
          sponsor_pay: $sponsor_pay
          sponsor_pay_status: $sponsor_pay_status
        }
      }
    ) {
      sponsor {
        id
      }
    }
  }
`
export const updateSponsor = gql`
  mutation updateSponsor(
    $id: ID!
    $companyName_kr: String
    $companyName_en: String
    $homepage_en: String
    $bizNumber: String
    $address: String
    $email: String
    $phone: String
    $managerName: String
    $mobilePhone: String
    $grade: ENUM_SPONSOR_GRADE
    $partnership: String
    $businessRegistration: ID
    $sponsor_paper: ID
    $price: Long
    $taxBill: String
    $paymentSchedule: DateTime
    $taxBillSchedule: DateTime
    $taxBillEmail: String
    $manager_memo: String
    $sponsor_pay_status: String
  ) {
    updateSponsor(
      input: {
        where: { id: $id }
        data: {
          companyName_kr: $companyName_kr
          companyName_en: $companyName_en
          homepage_en: $homepage_en
          bizNumber: $bizNumber
          address: $address
          email: $email
          phone: $phone
          managerName: $managerName
          mobilePhone: $mobilePhone
          grade: $grade
          partnership: $partnership
          businessRegistration: $businessRegistration
          sponsor_paper: $sponsor_paper
          price: $price
          taxBill: $taxBill
          paymentSchedule: $paymentSchedule
          taxBillSchedule: $taxBillSchedule
          taxBillEmail: $taxBillEmail
          manager_memo: $manager_memo
          sponsor_pay_status: $sponsor_pay_status
        }
      }
    ) {
      sponsor {
        id
      }
    }
  }
`
export const deleteMultipleSponsor = gql`
  mutation deleteMultipleSponsor($id: [ID]) {
    deleteMultipleSponsor(data: { id: $id }) {
      response
    }
  }
`

export const changePayStatusAtResult = gql`
  mutation changePayStatusAtResult($tid: String, $status: String) {
    changePayStatusAtResult(data: { tid: $tid, status: $status }) {
      response
    }
  }
`

export const deleteMultipleRegister = gql`
  mutation deleteMultipleRegister($id: [ID]) {
    deleteMultipleRegister(data: { id: $id }) {
      response
    }
  }
`

export const checkupRegistpayment = gql`
  mutation checkupRegistpayment($id: [ID]) {
    checkupRegistpayment(data: { id: $id }) {
      response
    }
  }
`

export const forcePaymentCheck = gql`
  mutation forcePaymentCheck($email: String!) {
    forcePaymentCheck(data: { email: $email }) {
      response
    }
  }
`

export const createMemo = gql`
  mutation createMemo($relationId: String!, $type: String, $contents: String) {
    createMemoList(input: { data: { relationId: $relationId, type: $type, contents: $contents } }) {
      memoList {
        id
      }
    }
  }
`

export const updateMemo = gql`
  mutation updateMemo(
    $id: ID!
    $relationId: String!
    $type: String
    $contents: String
    $lastLog: String
  ) {
    updateMemoList(
      input: {
        where: { id: $id }
        data: { relationId: $relationId, type: $type, contents: $contents, lastLog: $lastLog }
      }
    ) {
      memoList {
        id
      }
    }
  }
`
