<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.296"
    height="22.008"
    viewBox="0 0 28.296 22.008"
  >
    <path
      id="mail_FILL0_wght200_GRAD0_opsz24"
      d="M122.539-737.992a2.46,2.46,0,0,1-1.812-.727,2.46,2.46,0,0,1-.727-1.812v-16.93a2.46,2.46,0,0,1,.727-1.812,2.46,2.46,0,0,1,1.812-.727h23.218a2.46,2.46,0,0,1,1.812.727,2.46,2.46,0,0,1,.727,1.812v16.93a2.46,2.46,0,0,1-.727,1.812,2.46,2.46,0,0,1-1.812.727Zm11.609-10.823-12.576-8.223v16.506a.941.941,0,0,0,.272.7.942.942,0,0,0,.7.272h23.218a.942.942,0,0,0,.7-.272.941.941,0,0,0,.272-.7v-16.506Zm0-1.753,12.092-7.86H122.056Zm-12.576-6.469v16.506a.941.941,0,0,0,.272.7.942.942,0,0,0,.7.272h-.967Z"
      transform="translate(-120 760)"
      fill="#fff"
    />
  </svg>
</template>
