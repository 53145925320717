<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="59.391"
    height="79.188"
    viewBox="0 0 59.391 79.188"
  >
    <path
      id="file-lines-solid"
      d="M9.9,0A9.908,9.908,0,0,0,0,9.9V69.289a9.908,9.908,0,0,0,9.9,9.9H49.492a9.908,9.908,0,0,0,9.9-9.9V24.746h-19.8A4.944,4.944,0,0,1,34.645,19.8V0Zm29.7,0V19.8h19.8ZM17.322,39.594H42.068a2.475,2.475,0,0,1,0,4.949H17.322a2.475,2.475,0,0,1,0-4.949Zm0,9.9H42.068a2.475,2.475,0,0,1,0,4.949H17.322a2.475,2.475,0,0,1,0-4.949Zm0,9.9H42.068a2.475,2.475,0,0,1,0,4.949H17.322a2.475,2.475,0,0,1,0-4.949Z"
      fill="#111c58"
    />
  </svg>
</template>
