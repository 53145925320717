import country from '@/assets/data/country.json'
import manage from '@/assets/data/manage.json'
import template from '@/assets/data/template.json'
import store from '@/store'

/**manage data have a select items or something other datas without grouping
 * if you want to add JSON format static data then check the data and take a side
@param {string} x  x is a key of those data which place in manage
@author 조재호
*/
function progressTemplateData(x) {
  const new_data = JSON.parse(JSON.stringify(template[x]))
  return new_data
}

/**manage data have a select items or something other datas without grouping
 * if you want to add JSON format static data then check the data and take a side
@param {string} x  x is a key of those data which place in manage
@author 조재호
*/
function progressManageData(x) {
  const new_data = JSON.parse(JSON.stringify(manage[x]))
  return new_data
}

/**converting data for visualization in TIER_LIST
@param {string} x tier value which used in the TIER_LIST
@return {string} Tier title will be returned
@author 조재호
*/
function convertCategory(x) {
  const list_up = progressManageData('TIER_LIST')
  for (let index = 0; index < list_up.length; index++) {
    if (list_up[index].value === x) {
      return list_up[index].title
    }
  }
}

/**progressing country data in all data from country.json(refer from assets)
@param {string} x type of data what you want ('NAME','CODE','DIAL_CODE') available
@returns {string[]} Array type data return
@author 조재호
*/
function progressCountryData(x) {
  const new_select_box = []
  for (let i = 0; i < country.length; i++) {
    new_select_box.push(country[i][x])
  }
  return new_select_box
}

/**progressing time data for annotation in 12hour table or 24hour table in data
@param {string} type type in 12hour or 24hour
@param {string|string[]} list list became includes function you can use just 'hour' or ['hour'] is same in function.
@returns {object} object for hour/indication(12hour Only)/minute/seconds each data became Array
@author 조재호
*/
function progressTimeData(type, list) {
  const form = {}
  if (list.includes('hour')) {
    let hour = []
    if (type === '12hour') {
      for (let index = 1; index < 13; index++) {
        hour.push(index)
      }
      form.indication = ['A.M.', 'P.M.']
    }
    if (type === '24hour') {
      for (let index = 0; index < 24; index++) {
        hour.push(index)
      }
    }
    form.hour = hour
  }
  if (list.includes('minute')) {
    let min = []
    for (let index = 0; index < 60; index++) {
      min.push(index)
    }
    form.minute = min
  }
  if (list.includes('seconds')) {
    let seconds = []
    for (let index = 0; index < 60; index++) {
      seconds.push(index)
    }
    form.seconds = seconds
  }
  return form
}

/**converting string or number contents makeup currency with out float.
@param {string | integer} price the contents of price from user
@return {string} contents with ',' which positioned in 3rd digit
@author 조재호
*/
function convertCurrency(price) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**convert simple title in data which can not use i18n
@param {string} title title from DB
@return {string} "Mr"/"Ms"/"Dr"/"Prof"/"Other" default value is "Other"
@author 조재호
*/
function convertTitle(title) {
  switch (title) {
    case 'mister':
      return 'Mr.'
    case 'miss':
      return 'Ms.'
    case 'other':
      return 'Other'
    case 'doctor':
      return 'Dr.'
    case 'professor':
      return 'Prof.'
    default:
      return 'Other'
  }
}

/**convert degree which can not use i18n 
@param {string} degree degree string from DB
@return {string} "Ph.D"/"M.D."/"M.D. & Ph.D"/"Other" default value is "Other"
@author 조재호 
*/
function convertDegree(degree) {
  switch (degree) {
    case 'philosophy':
      return 'Ph.D.'
    case 'master':
      return 'M.D.'
    case 'master,philosophy':
      return 'M.D.& Ph.D.'
    case 'other':
      return 'Other'
    default:
      return 'Other'
  }
}

/**convert Abst Category for database
@param {string} category degree string from DB
@return {string} "Error for default casereport become Case Report original equal Original"
@author 조재호 
*/
function convertAbstCategory(category) {
  switch (category) {
    case 'casereport':
      return 'Case Report'
    case 'original':
      return 'Original'
    default:
      return 'Error'
  }
}

/**convert variable object keys to front side
@param {string} variable single_variable_key_name
@returns {string} converted string data
@author 조재호
*/
function convertVariable(variable) {
  switch (variable) {
    case 'users-permissions_user.username':
      return '유저아이디'
    case 'users-permissions_user.email':
      return '유저이메일'
    case 'users-permissions_user.blocked':
      return '유저휴면계정여부'
    case 'users-permissions_user.title':
      return '유저명칭'
    case 'users-permissions_user.familyName':
      return '유저성씨(EN)'
    case 'users-permissions_user.name_kr':
      return '유저성명(KR)'
    case 'users-permissions_user.givenName':
      return '유저이름(EN)'
    case 'users-permissions_user.degree':
      return '유저학위'
    case 'users-permissions_user.affiliation':
      return '유저소속(EN)'
    case 'users-permissions_user.phone':
      return '유저전화번호'
    case 'users-permissions_user.mobilePhone':
      return '유저휴대전화번호'
    case 'users-permissions_user.address':
      return '유저직장주소'
    case 'users-permissions_user.department':
      return '유저소속부서'
    case 'users-permissions_user.fax':
      return '유저팩스번호'
    case 'users-permissions_user.visa':
      return '유저비자업무필요여부'
    case 'users-permissions_user.receiveInfo':
      return '유저정보수신여부'
    case 'users-permissions_user.viaEmail':
      return '유저이메일수신여부'
    case 'users-permissions_user.howFind':
      return '유저접근성앙케이트답변'
    case 'users-permissions_user.affiliation_kr':
      return '유저소속(KR)'
    case 'users-permissions_user.country':
      return '유저국적'
    case 'users-permissions_user.invited':
      return '유저초빙객여부'
    case 'users-permissions_user.medi_license':
      return '유저면허번호'
    case 'users-permissions_user.occupation':
      return '유저직업'
    case 'abstracts.preference':
      return '초록종류'
    case 'abstracts.category':
      return '초록범주'
    case 'abstracts.title':
      return '초록제목'
    case 'abstracts.objectives':
      return '초록내용(목적)'
    case 'abstracts.methods':
      return '초록내용(방법론)'
    case 'abstracts.results':
      return '초록내용(결과)'
    case 'abstracts.conclusions':
      return '초록내용(결론)'
    case 'abstracts.keywords':
      return '초록키워드'
    case 'abstracts.author':
      return '초록저자'
    case 'abstracts.including':
      return '초록저자소속'
    case 'abstracts.terms':
      return '초록제출규약'
    case 'abstracts.status':
      return '초록검토상태'
    case 'abstracts.topic':
      return '초록토픽'
    case 'registration_lists.price':
      return '등록회원결제액'
    case 'registration_lists.price_type':
      return '등록회원결제방법'
    case 'registration_lists.price_status':
      return '등록회원결제상태'
    case 'registration_lists.price_date':
      return '등록회원결제일'
    case 'registration_lists.attend':
      return '등록회원참석방법'
    case 'registration_lists.category':
      return '등록회원등급명'
    case 'registration_lists.dinnerAttendance':
      return '등록회원갈라디너참여'
    case 'registration_lists.vegetarian':
      return '등록회원채식여부'
    case 'registration_lists.dietaryNeeds':
      return '등록회원알레르기여부'
    case 'registration_lists.accompanyingPerson':
      return '등록회원갈라디너동반인원'
    case 'registration_lists.currency':
      return '등록회원결제화폐단위'
    case 'registration_lists.alergy':
      return '등록회원알레르기상세'
    case 'pays.sid':
      return '국내외결제유형'
    case 'pays.cash_code':
      return '결제방식'
    case 'pays.product_name':
      return '결제한상품명'
    case 'pays.total':
      return '결제총액'
    case 'pays.tax':
      return '결제시세금'
    case 'pays.tax_free':
      return '결제세금면제여부'
    case 'pays.tax_free':
      return '결제세금면제여부'
    case 'pays.trade_id':
      return '거래고유아이디'
    case 'pays.tid':
      return '결제고유아이디'
    case 'pays.user_name':
      return '결제한유저이름'
    case 'pays.user_email':
      return '결제한유저이메일'
    case 'pays.pay_url':
      return '결제URL'
    case 'pays.time_stamp':
      return '결제시점시간'
    case 'pays.qrcode_url':
      return '결제QR코드URL'
    case 'pays.pay_status':
      return '결제상태'
    case 'pays.cn_appr_no':
      return '결제카드승인넘버'
    case 'send_emails.authcode':
      return '비밀번호찾기인증번호'
    case 'send_emails.title':
      return '이메일타이틀'
    case 'send_emails.type':
      return '송부한메일형식'
    default:
      return '비지정형식'
  }
}

/**increase digit on number which need '0' can use if you want to do.
 * If you want 001 in there x is 1 y is 3  then it check the digit which is under 3 digits
 * and add '0'
@param {string | number} x 'x' is value of the original which need to add '0'
@param {number} y 'y' is value of digit which you want to convert. 
@return {string} digit is only string data
@author 조재호 
*/
function convertNumberDigit(x, y) {
  if (y !== null && y > 1) {
    let char_length = String(x).length
    let digit = ''
    for (let index = 0; index < y - char_length; index++) {
      digit += '0'
    }
    digit += String(x)
    return digit
  } else {
    return x
  }
}

/**make phone number without hyphen make phone number with hyphen.
 * convert if have the foramt +IntenalCode/PhoneNumber then checkup the
 * internal code and check up and replace it with hyphen
@param {string} phone_number phone number updated
@return {string} phone number with hyphen which replaced phone_number
@author 조재호
*/
function convertPhoneReg(phone_number) {
  const int_code_regex = /\+(\d{2,4})\//g
  if (int_code_regex.test(phone_number)) {
    return phone_number
      .replace(int_code_regex, '')
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
  } else {
    return phone_number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
  }
}

async function convertMailWithTemplate(contents, dataset, variable) {
  if (!Array.isArray(variable) || variable.length === 0) {
    return false
  }
  const variable_list = variable
  let new_array = []
  for (let index = 0; index < variable_list.length; index++) {
    await store.dispatch('getColumnKeys', { table: variable_list[index] }).then((res) => {
      new_array = new_array.concat(res.data.response)
    })
  }
  let replaced_contents = contents
  const data_set = dataset
  if (new_array.length > 0) {
    for (let varIdx = 0; varIdx < new_array.length; varIdx++) {
      const check_regex = new RegExp(`{${new_array[varIdx]}}`, 'gm')
      replaced_contents = replaced_contents.replace(check_regex, data_set[new_array[varIdx]])
    }
  }
  return replaced_contents
}

const globalProgress = {
  progressCountryData,
  progressTimeData,
  progressTemplateData,
  convertTitle,
  convertDegree,
  progressManageData,
  convertNumberDigit,
  convertCurrency,
  convertCategory,
  convertVariable,
  convertPhoneReg,
  convertMailWithTemplate,
  convertAbstCategory,
}

export default globalProgress
