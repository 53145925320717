/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts } from './webfontloader'
import { createHead } from '@vueuse/head'
import vuetify from './vuetify'
import i18n from './i18n'
import router from '../router'
import globalFunction from '@/function/index'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
// import apolloProvider from '@/apollo/config'

//TimezoneSetting
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Seoul')
const head = createHead()

export function registerPlugins(app) {
  loadFonts()
  app.use(head).use(i18n).use(vuetify).use(router)
  app.config.globalProperties.$i18n = i18n
  app.config.globalProperties.$vuetify = vuetify
  app.config.globalProperties.$globalfunc = globalFunction
  app.config.globalProperties.$dayjs = dayjs
  // .use(apolloProvider)
}
