export const adminStore = {
  // getters, mutations, actions 도 각 모듈별로 구분해서 사용하려면 true 로 세팅해서 사용할 것
  // namespaced: true,
  state: () => ({
    main_nav: {},
    selectedSubMenus: [],
    left_nav_menu: [
      {
        title: '행사통계',
        route_name: 'committee',
        sub_menu: [{ title: '행사통계', route_name: 'eventStatistics' }],
      },
      {
        title: '등록관리',
        route_name: 'regist',
        sub_menu: [
          { title: '등록회원명단', route_name: 'committeUserData' },
          { title: '등록환불명단', route_name: 'refundData' },
          { title: '초빙Speaker명단', route_name: 'invitedSpeaker' },
          { title: '등록실패로그', route_name: 'registerLog' },
          { title: '결제로그', route_name: 'payLog' },
        ],
      },
      {
        title: '초록관리',
        route_name: 'abstract',
        sub_menu: [{ title: '초록관리', route_name: 'abstractData' }],
      },
      {
        title: '회원관리',
        route_name: 'member',
        sub_menu: [
          { title: '모든 회원관리', route_name: 'allUsers' },
          { title: '휴면계정관리', route_name: 'unregistered' },
        ],
      },
      {
        title: '메일관리',
        route_name: 'mail',
        sub_menu: [
          { title: '템플릿 설정', route_name: 'templateSetting' },
          { title: '송부메일 이력', route_name: 'mailLog' },
          { title: '자동메일 송부설정', route_name: 'autoSending' },
        ],
      },
      {
        title: '일반설정',
        route_name: 'general',
        sub_menu: [
          // { title: '마이그룹 관리', route_name: 'myGroup' },
          { title: '팝업관리', route_name: 'popupSetting' },
          { title: '게시글관리', route_name: 'bbsSetting' },
        ],
      },
      {
        title: '스폰서관리',
        route_name: 'sponsor',
        sub_menu: [{ title: '스폰서명단', route_name: 'sponsorData' }],
      },
    ],
    error_dialog: {
      open: false,
      title: '',
      text: '',
      modalIcon: 'error',
    },
    success_dialog: {
      open: false,
      title: '',
      text: '',
      modalIcon: 'success',
    },
  }),
  getters: {},
  mutations: {},
  actions: {},
}
