<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="59.391"
    height="67.875"
    viewBox="0 0 59.391 67.875"
  >
    <path
      id="passport-solid"
      d="M0,8.484A8.492,8.492,0,0,1,8.484,0H50.907a8.492,8.492,0,0,1,8.484,8.484V59.391a8.492,8.492,0,0,1-8.484,8.484H8.484A8.492,8.492,0,0,1,0,59.391ZM24.26,36.96a12.751,12.751,0,0,1-7.119-9.386h5.183A28.987,28.987,0,0,0,24.26,36.96Zm5.475,1.22h-.08a13.863,13.863,0,0,1-1.206-2.187,24.454,24.454,0,0,1-1.882-8.418h6.244a24.147,24.147,0,0,1-1.882,8.418,13.862,13.862,0,0,1-1.206,2.187Zm5.4-1.22a29.8,29.8,0,0,0,1.936-9.386H42.25A12.751,12.751,0,0,1,35.131,36.96Zm1.936-13.628a28.987,28.987,0,0,0-1.936-9.386,12.751,12.751,0,0,1,7.119,9.386ZM29.656,12.727h.08a13.863,13.863,0,0,1,1.206,2.187,24.454,24.454,0,0,1,1.882,8.418H26.58a24.147,24.147,0,0,1,1.882-8.418,13.862,13.862,0,0,1,1.206-2.187Zm-5.4,1.22a29.8,29.8,0,0,0-1.936,9.386H17.141A12.751,12.751,0,0,1,24.26,13.946Zm22.4,11.507A16.969,16.969,0,1,0,29.7,42.422,16.969,16.969,0,0,0,46.664,25.453ZM14.848,50.907a2.121,2.121,0,0,0,0,4.242h29.7a2.121,2.121,0,1,0,0-4.242Z"
      fill="#111c58"
    />
  </svg>
</template>
