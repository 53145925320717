import gql from 'graphql-tag'

export const duplicateUserName = gql`
  query duplicateUserName($username: String) {
    duplicateUserName(where: { username: $username }) {
      check
      delete
    }
  }
`

// admin
export const abstractGetUsers = gql`
  query users(
    $id: [ID]
    $email: String
    $country: String
    $name_kr: String
    $sort_conditions: String
    $page: Int
    $perpage: Int
    $invited: Boolean
    $delete: Boolean
    $blocked: Boolean
    $givenName: String
    $familyName: String
    $registration_list_null: Boolean
    $affiliation: String
  ) {
    usersConnection(
      where: {
        id: $id
        country: $country
        email_contains: $email
        invited: $invited
        name_kr_contains: $name_kr
        givenName_contains: $givenName
        familyName_contains: $familyName
        delete: $delete
        blocked: $blocked
        registration_list_null: $registration_list_null
        affiliation: $affiliation
      }
    ) {
      aggregate {
        count
      }
    }

    users(
      where: {
        id: $id
        country: $country
        email_contains: $email
        invited: $invited
        givenName_contains: $givenName
        familyName_contains: $familyName
        name_kr_contains: $name_kr
        delete: $delete
        blocked: $blocked
        affiliation_contains: $affiliation
        registration_list_null: $registration_list_null
      }
      sort: $sort_conditions
      start: $page
      limit: $perpage
    ) {
      id
      email
      degree
      givenName
      familyName
      affiliation
      department
      country
      title
      name_kr
      created_at
      phone
      mobilePhone
      fax
      affiliation_kr
      address
      registration_list {
        id
      }
    }
  }
`

export const getMe = gql`
  query {
    me {
      id
      username
      email
      mobilePhone
      givenName
      familyName
      invited
      delete
      country
      title
      role {
        name
      }
    }
  }
`

export const getMyData = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      country
      givenName
      familyName
      name_kr
      affiliation
      department
      title
      degree
      address
      phone
      mobilePhone
      fax
      visa
      viaEmail
      receiveInfo
      howFind
      affiliation_kr
      invited
      occupation
      occupationOther
      medi_license
      visaGivenName
      visaFamilyName
      visaCountry
      visaPassportNumber
      visaBirth
      visaIssue
      visaExpire
    }
  }
`

export const getAbstracts = gql`
  query getAbstracts(
    $id: ID
    $title: String
    $email: String
    $krName: String
    $enName: String
    $userId: ID
    $sort_conditions: String
    $start: Int
    $limit: Int
    $startDate: DateTime
    $country: String
    $endDate: DateTime
    $topic: String
    $preference: String
    $delete: Boolean
  ) {
    abstractsConnection(
      where: {
        created_at_gte: $startDate
        created_at_lte: $endDate
        delete: $delete
        topic: $topic
        title_contains: $title
        preference: $preference
        users_permissions_user: {
          id: $userId
          username_contains: $email
          name_kr_contains: $krName
          familyName_contains: $enName
          givenName_contains: $enName
          country: $country
        }
      }
    ) {
      aggregate {
        count
      }
    }

    abstracts(
      where: {
        preference: $preference
        created_at_gte: $startDate
        created_at_lte: $endDate
        delete: $delete
        users_permissions_user: {
          id: $userId
          username_contains: $email
          name_kr_contains: $krName
          familyName_contains: $enName
          givenName_contains: $enName
          country: $country
        }
        id: $id
        topic: $topic
        title_contains: $title
      }
      start: $start
      limit: $limit
      sort: $sort_conditions
    ) {
      id
      created_at
      preference
      category
      title
      delete
      history
      author
      including
      results
      methods
      objectives
      topic
      keywords
      topic
      conclusions
      terms
      users_permissions_user {
        username
        email
        familyName
        givenName
        name_kr
        country
        degree
        affiliation
      }
      file_img {
        id
        width
        height
        url
        name
      }
    }
  }
`

export const permittedAbstract = gql`
  query permittedAbstract($id: ID!, $userId: ID) {
    permittedAbstract(where: { id: $id, userID: $userId }) {
      response
    }
  }
`

export const abstractTypeCount = gql`
  query abstractTypeCount($userId: String) {
    abstractTypeCount(where: { userId: $userId }) {
      response
    }
  }
`

export const getSpeakers = gql`
  query getSpeaker($userId: ID) {
    speakers(where: { users_permissions_user: $userId }) {
      id
      file_cv {
        id
        url
        name
      }
      file_photo {
        id
        url
        name
      }
      file_lecture {
        id
        url
        name
      }
      file_presentation {
        id
        url
        name
      }
    }
  }
`

export const getGroups = gql`
  query getGroups($title: String, $free_check: Boolean, $type: String, $start: Int, $limit: Int) {
    groupsConnection(
      where: { delete: false, title_contains: $title, free: $free_check, type_contains: $type }
    ) {
      aggregate {
        count
      }
    }

    groups(
      where: { delete: false, title_contains: $title, free: $free_check, type_contains: $type }
      start: $start
      limit: $limit
    ) {
      id
      title
      type
      free
      group_user_id
    }
  }
`

export const getSingleGroup = gql`
  query getGroup($id: ID!) {
    group(id: $id) {
      id
      title
      type
      free
      group_user_id
    }
  }
`

export const getPopups = gql`
  query getPopups(
    $title: String
    $start: Int
    $limit: Int
    $useType: Boolean
    $startDate: DateTime
    $endDate: DateTime
    $checkToday: DateTime
    $sort_conditions: String
  ) {
    popupsConnection(
      where: {
        title_contains: $title
        use: $useType
        start_gte: $startDate
        delete: false
        _or: [{ end_lte: $endDate }, { end_eq: "3000-12-31T23:59:59" }]
      }
    ) {
      aggregate {
        count
      }
    }
    popups(
      where: {
        title_contains: $title
        use: $useType
        start_gte: $startDate
        delete: false
        _or: [{ end_lte: $endDate }, { end_gte: $checkToday }, { end_eq: "3000-12-31T23:59:59" }]
      }
      start: $start
      limit: $limit
      sort: $sort_conditions
    ) {
      id
      use
      title
      start
      end
      content
      size_width
      size_height
      location
    }
  }
`

export const getSinglePopup = gql`
  query getPopups($id: ID!) {
    popup(id: $id) {
      id
      use
      title
      start
      end
      size_width
      size_height
      location
      created_name
      content
    }
  }
`

export const getInvitedUser = gql`
  query users(
    $email: String
    $country: String
    $name_kr: String
    $sort_conditions: String
    $page: Int
    $perpage: Int
    $givenName: String
    $familyName: String
  ) {
    usersConnection(
      where: {
        country: $country
        email_contains: $email
        invited: true
        name_kr_contains: $name_kr
        givenName_contains: $givenName
        familyName_contains: $familyName
        delete: false
        blocked: false
      }
    ) {
      aggregate {
        count
      }
    }

    users(
      where: {
        country: $country
        email_contains: $email
        invited: true
        givenName_contains: $givenName
        familyName_contains: $familyName
        name_kr_contains: $name_kr
        delete: false
        blocked: false
      }
      sort: $sort_conditions
      start: $page
      limit: $perpage
    ) {
      id
      email
      givenName
      familyName
      country
      name_kr
      created_at
      updated_at
      mobilePhone
      speaker {
        id
        created_id
        created_at
        updated_at
        file_cv {
          id
          url
          name
        }
        file_lecture {
          id
          url
          name
        }
        file_presentation {
          id
          url
          name
        }
        file_photo {
          id
          url
          name
        }
      }
    }
  }
`

export const getSingleUser = gql`
  query getSingleUser($id: ID!) {
    user(id: $id) {
      id
      email
      givenName
      familyName
      country
      name_kr
      created_at
      speaker {
        id
        updated_at
        file_cv {
          id
          url
          name
        }
        file_lecture {
          id
          url
          name
        }
        file_presentation {
          id
          url
          name
        }
        file_photo {
          id
          url
          name
        }
      }
    }
  }
`

export const getNewsAndNotices = gql`
  query getNewsAndNotices(
    $title: String
    $type: [String]
    $created_name: String
    $nor_type: String
    $start: Int
    $limit: Int
    $sort_conditions: String
  ) {
    newsAndNoticesConnection(
      where: {
        title_contains: $title
        created_name_contains: $created_name
        type: $type
        delete: false
      }
    ) {
      aggregate {
        count
      }
    }
    newsAndNotices(
      where: {
        title_contains: $title
        created_name_contains: $created_name
        type: $type
        delete: false
        type_ne: $nor_type
      }
      sort: $sort_conditions
      start: $start
      limit: $limit
    ) {
      id
      title
      type
      viewCount
      created_name
      created_at
    }
  }
`

export const getNewsAndNotice = gql`
  query getNewsAndNotice($id: ID!) {
    newsAndNotice(id: $id) {
      id
      title
      content
      type
      viewCount
      created_at
      gallery_main {
        id
        url
        name
      }
    }
  }
`
export const getSendEmails = gql`
  query getSendEmails(
    $type: String
    $start: Int
    $limit: Int
    $sort_conditions: String
    $title: String
    $usermail: String
  ) {
    sendEmailsConnection(where: { type: $type }) {
      aggregate {
        count
      }
    }
    sendEmails(
      where: { type: $type, title_contains: $title, usermail_contains: $usermail }
      start: $start
      limit: $limit
      sort: $sort_conditions
    ) {
      id
      usermail
      title
      type
      content
      created_at
      files {
        id
        url
        name
      }
    }
  }
`

export const getMailTemplates = gql`
  query getMailTemplates(
    $title: String
    $start: Int
    $limit: Int
    $type: String
    $sort_conditions: String
  ) {
    mailTemplatesConnection(where: { title_contains: $title, delete: false, type: $type }) {
      aggregate {
        count
      }
    }
    mailTemplates(
      where: { title_contains: $title, delete: false, type: $type }
      start: $start
      limit: $limit
      sort: $sort_conditions
    ) {
      id
      title
      contents
      type
    }
  }
`

export const getMailTemplate = gql`
  query getMailTemplate($id: ID!) {
    mailTemplate(id: $id) {
      id
      title
      contents
    }
  }
`

export const getPayLogs = gql`
  query getPayLogs(
    $id: ID
    $user_email: String
    $user_name: String
    $pay_email: String
    $startDate: DateTime
    $endDate: DateTime
    $cash_code: ENUM_PAY_CASH_CODE
    $product_name: String
    $grade: String
    $start: Int
    $limit: Int
    $pay_status: String
    $sort_conditions: String
    $regist_id: ID
    $registration_list_null: Boolean
    $regist_delete: Boolean
  ) {
    paysConnection(
      where: {
        user_email_contains: $user_email
        user_name_contains: $user_name
        created_at_gte: $startDate
        created_at_lte: $endDate
        cash_code: $cash_code
        grade: $grade
        product_name: $product_name
        pay_status: $pay_status
        registration_list: { delete: $regist_delete }
      }
    ) {
      aggregate {
        count
      }
    }
    pays(
      where: {
        id: $id
        user_email: $pay_email
        user_email_contains: $user_email
        user_name_contains: $user_name
        created_at_gte: $startDate
        created_at_lte: $endDate
        cash_code: $cash_code
        grade: $grade
        registration_list_null: $registration_list_null
        product_name: $product_name
        pay_status: $pay_status
        registration_list: { id: $regist_id, delete: $regist_delete }
      }
      sort: $sort_conditions
      start: $start
      limit: $limit
    ) {
      id
      sid
      product_name
      created_at
      cash_code
      pay_status
      user_id
      user_name
      user_email
      trade_id
      total
      grade
      country
      registration_list {
        price_status
        category
        users_permissions_user {
          id
        }
      }
    }
  }
`

export const getRegistrationLists = gql`
  query getRegistrationLists(
    $user_id: ID
    $price_status: String
    $attend: String
    $category: String
    $start: Int
    $limit: Int
    $tid: String
    $delete: Boolean
    $not_equal: String
    $sort_conditions: String
    $startDate: DateTime
    $endDate: DateTime
    $name_kr: String
    $givenName: String
    $familyName: String
    $country: String
    $email: String
    $pay_null: Boolean
    $_or: JSON
  ) {
    registrationListsConnection(
      where: {
        price_status: $price_status
        category: $category
        created_at_gte: $startDate
        created_at_lte: $endDate
        attend: $attend
        delete: $delete
        price_status_ne: $not_equal
        users_permissions_user: {
          username_contains: $email
          name_kr_contains: $name_kr
          familyName_contains: $familyName
          givenName_contains: $givenName
          country: $country
        }
      }
    ) {
      aggregate {
        count
      }
    }
    registrationLists(
      where: {
        _or: $_or
        price_status: $price_status
        category: $category
        attend: $attend
        created_at_gte: $startDate
        created_at_lte: $endDate
        delete: $delete
        price_status_ne: $not_equal
        pay_null: $pay_null
        pay: { tid: $tid }
        users_permissions_user: {
          id: $user_id
          username_contains: $email
          name_kr_contains: $name_kr
          familyName_contains: $familyName
          givenName_contains: $givenName
          country: $country
        }
      }
      sort: $sort_conditions
      start: $start
      limit: $limit
    ) {
      id
      category
      price
      price_type
      price_date
      attend
      currency
      price_status
      dinnerAttendance
      accompanyingPerson
      vegetarian
      dietaryNeeds
      created_at
      alergy
      pay {
        id
        sid
        trade_id
        total
        tid
        product_name
        pay_status
        urlExpired
        country
      }
      users_permissions_user {
        id
        medi_license
        occupation
        name_kr
        country
        email
        givenName
        familyName
        affiliation
        mobilePhone
        title
        degree
        affiliation
        affiliation_kr
        department
      }
    }
  }
`

export const getSingleRegist = gql`
  query getSingleRegist($id: ID!) {
    registrationList(id: $id) {
      id
      category
      price
      price_type
      price_date
      attend
      currency
      price_status
      dinnerAttendance
      vegetarian
      dietaryNeeds
      accompanyingPerson
      created_at
      alergy
      users_permissions_user {
        id
        name_kr
        country
        email
        givenName
        familyName
        affiliation
      }
      pay {
        id
        trade_id
      }
    }
  }
`
export const getSponsors = gql`
  query getSponsors(
    $email: String
    $sort_conditions: String
    $managerName: String
    $companyName_en: String
    $start: Int
    $limit: Int
    $delete: Boolean
    $grade: String
  ) {
    sponsorsConnection(
      where: {
        companyName_en_contains: $companyName_en
        managerName_contains: $managerName
        email_contains: $email
        grade: $grade
        delete: $delete
      }
    ) {
      aggregate {
        count
      }
    }

    sponsors(
      where: {
        companyName_en_contains: $companyName_en
        managerName_contains: $managerName
        email_contains: $email
        grade: $grade
        delete: $delete
      }
      start: $start
      limit: $limit
      sort: $sort_conditions
    ) {
      id
      created_at
      companyName_kr
      companyName_en
      homepage_en
      managerName
      email
      mobilePhone
      grade
      partnership
      taxBillSchedule
      taxBill
      price
    }
  }
`
export const getExcelSponsors = gql`
  query getExcelSponsors($id: [ID]) {
    sponsors(where: { id: $id }) {
      id
      companyName_kr
      companyName_en
      homepage_en
      bizNumber
      address
      managerName
      email
      phone
      mobilePhone
      grade
      partnership
      businessRegistration {
        id
        name
        url
      }
      sponsor_paper {
        id
        name
        url
      }
      price
      paymentSchedule
      taxBill
      taxBillSchedule
      taxBillEmail
      manager_memo
      sponsor_pay
      sponsor_pay_status
      created_at
    }
  }
`
export const getSingleSponsor = gql`
  query getSingleSponsor($id: ID!) {
    sponsor(id: $id) {
      id
      companyName_kr
      companyName_en
      homepage_en
      bizNumber
      address
      managerName
      email
      phone
      mobilePhone
      grade
      partnership
      businessRegistration {
        id
        name
        url
      }
      sponsor_paper {
        id
        name
        url
      }
      price
      paymentSchedule
      taxBill
      taxBillSchedule
      taxBillEmail
      manager_memo
      sponsor_pay
      sponsor_pay_status
      created_at
    }
  }
`

export const checkDuplicateRegister = gql`
  query checkDuplicateRegister($id: ID) {
    checkDuplicateRegister(where: { id: $id }) {
      response
    }
  }
`

export const validateCheckPayUrl = gql`
  query validateCheckPayUrl($pay_url: String) {
    validateCheckPayUrl(where: { pay_url: $pay_url }) {
      response
    }
  }
`

export const allNewsNoticeLists = gql`
  query allNewsNoticeLists {
    allNewsNoticeLists {
      response
    }
  }
`

export const getMemoLists = gql`
  query getMemoLists($relationId: String, $type: String) {
    memoLists(where: { relationId: $relationId, type: $type }) {
      id
      relationId
      contents
      type
    }
  }
`
