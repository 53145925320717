<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="59.391"
    height="41.573"
    viewBox="0 0 59.391 41.573"
  >
    <path
      id="cloud-solid"
      d="M0,60.211A13.366,13.366,0,0,0,13.363,73.573h34.15a11.877,11.877,0,0,0,2.376-23.515,8.919,8.919,0,0,0-8.315-12.119,8.863,8.863,0,0,0-4.946,1.5A14.849,14.849,0,0,0,8.909,46.848c0,.251.009.5.019.752A13.372,13.372,0,0,0,0,60.211Z"
      transform="translate(0 -32)"
      fill="#111c58"
    />
  </svg>
</template>
