/**
 * main.js
 * Vuetify and other plugins then mounts the App`
 */

import App from './App.vue'
import store from '@/store'
import router from './router'
import { createApp } from 'vue'
import { registerPlugins } from '@/plugins'
import axios from 'axios'
// import router from './router'

const TOKENNAME = 'mcircle-ksp'

//When you check up your router guard in here
router.beforeEach(async (to, from, next) => {
  // admin side router guard
  if (to.href.includes('/admin')) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (localStorage.getItem(TOKENNAME) === null) {
        window.alert('Login is required.')
        next('/admin/login')
      } else {
        if (store.state.meData.id) {
          if (store.state.meData.role.name === 'Authenticated') {
            next()
          } else {
            window.alert('This account has not permitted.')
            next('/home')
          }
        } else {
          await store
            .dispatch('getMe')
            .then((res) => {
              if (res.me.delete) {
                window.alert('This Account has deleted.')
                next('/home')
              } else {
                if (res.me.role.name === 'Authenticated') {
                  next()
                } else {
                  window.alert('This account has not permitted.')
                  next('/home')
                }
              }
            })
            .catch(() => {
              window.alert('Login has expired.\nPlease login again.')
              localStorage.removeItem(TOKENNAME)
              store.state.meData = {}
              next('/admin/login')
            })
        }
      }
    } else {
      if (localStorage.getItem(TOKENNAME) !== null) {
        await store
          .dispatch('getMe')
          .then((res) => {
            if (res.me.delete) {
              window.alert('This Account has deleted.')
              next('/home')
            } else {
              next()
            }
          })
          .catch(() => {
            localStorage.removeItem(TOKENNAME)
            store.state.meData = {}
            next()
          })
      } else {
        next()
      }
    }
  }
  // main side router guard
  else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (localStorage.getItem(TOKENNAME) === null) {
        window.alert('Login is required.')
        next('/auth/login')
      } else {
        await store
          .dispatch('getMe')
          .then(() => {
            next()
          })
          .catch(() => {
            window.alert('Login has expired.\nPlease login again.')
            localStorage.removeItem(TOKENNAME)
            store.state.meData = {}
            next('/auth/login')
          })
      }
    } else {
      if (localStorage.getItem(TOKENNAME) !== null) {
        await store
          .dispatch('getMe')
          .then(() => {
            next()
          })
          .catch(() => {
            localStorage.removeItem(TOKENNAME)
            store.state.meData = {}
            next()
          })
      } else {
        next()
      }
    }
  }
})

const app = createApp(App).use(store)

registerPlugins(app)

app.mount('#app')
