<template>
  <router-view v-resize="resizeWindow" />

  <v-dialog
    v-model="$store.state.loading"
    fullscreen
    transition="dialog-center-transition"
    class="bg-transparent loading_status"
  >
    <v-container style="height: 100vh">
      <v-row class="ma-0">
        <v-progress-circular indeterminate :size="110" :width="10" color="highlight_pink">
        </v-progress-circular>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script setup>
  import { computed, reactive } from 'vue'
  import { useHead } from '@vueuse/head'

  const siteData = reactive({
    title: `Korean Society of Pathologists 2024`,
    description: `KSP congress site for 2024 conference.`,
  })
  useHead({
    // Can be static or computed
    title: computed(() => siteData.title),
    meta: [
      {
        name: `description`,
        content: computed(() => siteData.description),
      },
    ],
  })
</script>
<script>
  // import axios from 'axios'

  export default {
    created() {
      this.resizeWindow()
    },
    methods: {
      resizeWindow() {
        this.$store.state.window_size.x = window.innerWidth
        this.$store.state.window_size.y = window.innerHeight
        this.$store.state.window_size.isMobile = this.$vuetify.display.mobile
      },
    },
  }
</script>

<style lang="scss">
  @import '@/assets/css/global.scss';
</style>
