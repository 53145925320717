// Composables
import { createRouter, createWebHistory } from 'vue-router'
import notAuth from '@/views/error/notAuthenticated.vue'
import notFound from '@/views/error/notFound.vue'

const routes = [
  {
    path: '/pilot',
    component: () => import('@/views/pilot/pilotPoster.vue'),
  },
  {
    path: '/success',
    name: 'paymentSuccess',
    meta: { requiresAuth: true },
    component: () => import('@/views/pay/payStatusSuccess.vue'),
  },
  {
    path: '/failed',
    name: 'paymentFailed',
    meta: { requiresAuth: true },
    component: () => import('@/views/pay/payStatusFailed.vue'),
  },
  {
    path: '/windowClose',
    meta: { requiresAuth: true },
    component: () => import('@/views/pay/paymentClose.vue'),
  },
  {
    path: '/payment/:payId',
    meta: { requiresAuth: true },
    component: () => import('@/views/pay/paymentCustom.vue'),
  },
  // admin
  {
    path: '/admin',
    redirect: '/admin/login',
    children: [
      {
        path: 'login',
        name: 'adminLogin',
        component: () =>
          import(/* webpackChunkName: "admin_login" */ '@/views/admin/auth/login.vue'),
      },
      {
        path: 'main',
        name: 'adminMain',
        redirect: '/admin/main/committee/eventStatistics',
        component: () => import(/* webpackChunkName: "admin_layout" */ '@/layouts/adminLayout'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'committee',
            name: 'committee',
            redirect: '/admin/main/committee/eventStatistics',
            children: [
              {
                path: 'eventStatistics',
                name: 'eventStatistics',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_event_statics" */ '@/views/admin/committe/eventStatistics.vue'
                  ),
              },
            ],
          },
          {
            path: 'regist',
            name: 'regist',
            redirect: '/admin/main/regist/committeUserData',
            children: [
              {
                path: 'committeUserData',
                name: 'committeUserData',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_user_data" */ '@/views/admin/registSetting/committeUserData.vue'
                  ),
              },
              {
                path: 'refundData',
                name: 'refundData',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_refund_data" */ '@/views/admin/registSetting/refundData.vue'
                  ),
              },

              {
                path: 'invitedSpeaker',
                name: 'invitedSpeaker',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_invited_speaker" */ '@/views/admin/registSetting/invitedSpeaker.vue'
                  ),
              },
              {
                path: 'registerLog',
                name: 'registerLog',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_invited_speaker" */ '@/views/admin/registSetting/registerLog.vue'
                  ),
              },
              {
                path: 'payLog',
                name: 'payLog',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_paylog" */ '@/views/admin/registSetting/payLog.vue'
                  ),
              },
            ],
          },
          {
            path: 'abstract',
            name: 'abstract',
            redirect: '/admin/main/abstract/abstractData',
            children: [
              {
                path: 'abstractData',
                name: 'abstractData',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_abstract_data" */ '@/views/admin/abstractSetting/abstractData.vue'
                  ),
              },
            ],
          },
          {
            path: 'sponsor',
            name: 'sponsor',
            redirect: '/admin/main/sponsor/sponsorData',
            children: [
              {
                path: 'sponsorData',
                name: 'sponsorData',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_abstract_data" */ '@/views/admin/sponsorSetting/sponsorData.vue'
                  ),
              },
            ],
          },
          {
            path: 'mail',
            name: 'mail',
            redirect: '/admin/main/mail/templateSetting',
            children: [
              {
                path: 'autoSending',
                name: 'autoSending',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_mail_auto_send" */ '@/views/admin/mailSetting/autoSending.vue'
                  ),
              },
              {
                path: 'mailLog',
                name: 'mailLog',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_mail_log" */ '@/views/admin/mailSetting/mailLog.vue'
                  ),
              },
              {
                path: 'templateSetting',
                name: 'templateSetting',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_template_setting" */ '@/views/admin/mailSetting/templateSetting.vue'
                  ),
              },
            ],
          },
          {
            path: 'general',
            name: 'general',
            redirect: '/admin/main/general/popupSetting',
            children: [
              // {
              //   path: 'myGroup',
              //   name: 'myGroup',
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "admin_mygroup" */ '@/views/admin/defaultSetting/myGroup.vue'
              //     ),
              // },
              {
                path: 'popupSetting',
                name: 'popupSetting',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_popup_set" */ '@/views/admin/defaultSetting/popupSetting.vue'
                  ),
              },
              {
                path: 'bbsSetting',
                name: 'bbsSetting',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_bbs_set" */ '@/views/admin/defaultSetting/bbsSetting.vue'
                  ),
              },
            ],
          },
          {
            path: 'member',
            name: 'member',
            redirect: '/admin/main/member/allUsers',
            children: [
              {
                path: 'allUsers',
                name: 'allUsers',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_users_refer" */ '@/views/admin/userSetting/allUsers.vue'
                  ),
              },
              {
                path: 'unregistered',
                name: 'unregistered',
                component: () =>
                  import(
                    /* webpackChunkName: "admin_unregister" */ '@/views/admin/userSetting/unregistered.vue'
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
  // front
  {
    path: '/',
    redirect: '/home',
    component: () => import(/* webpackChunkName: "front_pilot" */ '@/layouts/mainLayout.vue'),
    children: [
      {
        path: 'home',
        name: 'homePage',
        component: () => import(/* webpackChunkName: "front_home" */ '@/views/main/home/index.vue'),
      },
      {
        path: 'auth',
        name: 'auth',
        redirect: '/auth/login',
        component: () => import(/* webpackChunkName: "front_auth" */ '@/views/auth/index.vue'),
        children: [
          {
            path: 'login',
            name: 'login',
            component: () =>
              import(/* webpackChunkName: "front_auth_login" */ '@/views/auth/contents/login.vue'),
          },
          {
            path: 'signup',
            name: 'signup',
            component: () =>
              import(
                /* webpackChunkName: "front_auth_signup" */ '@/views/auth/contents/signUp.vue'
              ),
          },
          {
            path: 'find',
            name: 'find',
            component: () =>
              import(
                /* webpackChunkName: "front_auth_find" */ '@/views/auth/contents/findAccount.vue'
              ),
          },
          {
            path: 'sitemap',
            name: 'sitemap',
            component: () =>
              import(
                /* webpackChunkName: "front_auth_sitemap" */ '@/views/auth/contents/siteMap.vue'
              ),
          },
        ],
      },
      {
        path: 'mypage',
        name: 'mypage',
        redirect: '/mypage/my_information',
        component: () =>
          import(/* webpackChunkName: "front_mypage" */ '@/views/certificate/index.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'my_information',
            name: 'my_info',
            component: () =>
              import(
                /* webpackChunkName: "front_mypage_myinfo" */ '@/views/certificate/contents/personalInfo.vue'
              ),
          },
          {
            path: 'my_abstract',
            name: 'my_abstract',
            component: () =>
              import(
                /* webpackChunkName: "front_mypage_myabstract" */ '@/views/certificate/contents/myAbstract.vue'
              ),
          },
          {
            path: 'my_registration',
            name: 'my_registration',
            component: () =>
              import(
                /* webpackChunkName: "front_mypage_myregist" */ '@/views/certificate/contents/myRegistration.vue'
              ),
          },
          {
            path: 'sitemap',
            name: 'lg_sitemap',
            component: () =>
              import(
                /* webpackChunkName: "front_auth_sitemap" */ '@/views/auth/contents/siteMap.vue'
              ),
          },
          {
            path: 'abstract_modify/:abs_id',
            name: 'abstract_modify',
            component: () =>
              import(
                /* webpackChunkName: "front_mypage_modifyabstract" */ '@/views/certificate/contents/abstractModify.vue'
              ),
          },
          {
            path: 'registration_modify/:lang',
            name: 'registration_modify',
            component: () =>
              import(
                /* webpackChunkName: "front_mypage_myregist" */ '@/views/certificate/contents/registrationModify.vue'
              ),
          },
          {
            path: 'mbmypage',
            name: 'mobile_mypage',
            component: () =>
              import(
                /* webpackChunkName: "front_mypage_modifyabstract" */ '@/views/certificate/contents/mobileMypage.vue'
              ),
          },
        ],
      },
      {
        path: 'introductions',
        name: 'introductions',
        redirect: '/introductions/welcome',
        component: () =>
          import(/* webpackChunkName: "front_intro" */ '@/views/main/introduction/index.vue'),
        children: [
          {
            path: 'welcome',
            name: 'introduction_welcome',
            component: () =>
              import(
                /* webpackChunkName: "front_intro_welcome" */ '@/views/main/introduction/contents/welcome.vue'
              ),
          },
          {
            path: 'overview',
            name: 'introduction_overview',
            component: () =>
              import(
                /* webpackChunkName: "front_intro_overview" */ '@/views/main/introduction/contents/overview.vue'
              ),
          },
          {
            path: 'congress',
            name: 'introduction_congress',
            component: () =>
              import(
                /* webpackChunkName: "front_intro_congress" */ '@/views/main/introduction/contents/congress.vue'
              ),
          },
          {
            path: 'committees',
            name: 'introduction_committees',
            component: () =>
              import(
                /* webpackChunkName: "front_intro_committees" */ '@/views/main/introduction/contents/committees.vue'
              ),
          },
          {
            path: 'history',
            name: 'introduction_history',
            component: () =>
              import(
                /* webpackChunkName: "front_intro_history" */ '@/views/main/introduction/contents/history.vue'
              ),
          },
        ],
      },
      {
        path: 'programs',
        name: 'programs',
        redirect: '/programs/program_picforum',
        component: () =>
          import(/* webpackChunkName: "front_program" */ '@/views/main/program/index.vue'),
        children: [
          {
            path: 'program_glance',
            name: 'program_glance',
            component: () =>
              import(
                /* webpackChunkName: "front_program_glance" */ '@/views/main/program/contents/programGlance.vue'
              ),
          },
          {
            path: 'invited_speaker',
            name: 'program_invited',
            component: () =>
              import(
                /* webpackChunkName: "front_program_invited" */ '@/views/main/program/contents/programInvited.vue'
              ),
          },
          {
            path: 'program_picforum',
            name: 'program_picforum',
            component: () =>
              import(
                /* webpackChunkName: "front_program_forum" */ '@/views/main/program/contents/programPicforum.vue'
              ),
          },
          {
            path: 'program_scientific',
            name: 'program_scientific',
            component: () =>
              import(
                /* webpackChunkName: "front_program_scientific" */ '@/views/main/program/contents/programScientific.vue'
              ),
          },
          {
            path: 'social_program',
            name: 'program_social',
            component: () =>
              import(
                /* webpackChunkName: "front_program_social" */ '@/views/main/program/contents/programSocial.vue'
              ),
          },
        ],
      },
      {
        path: 'abstracts',
        name: 'abstracts',
        redirect: '/abstracts/abstract_guideline',
        component: () =>
          import(/* webpackChunkName: "front_abstract" */ '@/views/main/abstract/index.vue'),
        children: [
          {
            path: 'abstract_guideline',
            name: 'abstract_guideline',
            component: () =>
              import(
                /* webpackChunkName: "front_abstract_guide" */ '@/views/main/abstract/contents/abstractGuide.vue'
              ),
          },
          {
            path: 'abstract_submission',
            name: 'abstract_submission',
            meta: { requiresAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "front_abstract_submit" */ '@/views/main/abstract/contents/abstractSubmission.vue'
              ),
          },
          {
            path: 'awards',
            name: 'abstract_awards',
            component: () =>
              import(
                /* webpackChunkName: "front_abstract_awards" */ '@/views/main/abstract/contents/awards.vue'
              ),
          },
          {
            path: 'presentation_guideline',
            name: 'present_guideline',
            component: () =>
              import(
                /* webpackChunkName: "front_abstract_present" */ '@/views/main/abstract/contents/presentGuide.vue'
              ),
          },
          {
            path: 'presentation_upload',
            name: 'present_upload',
            component: () =>
              import(
                /* webpackChunkName: "front_abstract_invited" */ '@/views/main/abstract/contents/presentUpload.vue'
              ),
          },
        ],
      },
      {
        path: 'registrations',
        name: 'registrations',
        redirect: '/registrations/registration_korean',
        component: () =>
          import(/* webpackChunkName: "front_register" */ '@/views/main/registration/index.vue'),
        children: [
          {
            path: 'registration_korean',
            name: 'registration_korean',
            component: () =>
              import(
                /* webpackChunkName: "front_register_kr" */ '@/views/main/registration/contents/registKorean.vue'
              ),
          },
          {
            path: 'registration_foreigner',
            name: 'registration_foreigner',
            component: () =>
              import(
                /* webpackChunkName: "front_register_overseas" */ '@/views/main/registration/contents/registForeign.vue'
              ),
          },
          {
            path: 'registration/:lang',
            name: 'registration_form',
            meta: { requiresAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "front_register_form" */ '@/views/main/registration/contents/registerForm.vue'
              ),
          },
        ],
      },
      {
        path: 'sponsorships',
        name: 'sponsorships',
        redirect: '/sponsorships/sponsor',
        component: () =>
          import(/* webpackChunkName: "front_sponsor" */ '@/views/main/sponsorship/index.vue'),
        children: [
          {
            path: 'sponsor_apply',
            name: 'sponsor_apply',
            component: () =>
              import(
                /* webpackChunkName: "front_sponsor_apply" */ '@/views/main/sponsorship/contents/sponsorApply.vue'
              ),
          },
          {
            path: 'sponsor',
            name: 'sponsor_view',
            component: () =>
              import(
                /* webpackChunkName: "front_sponsor_view" */ '@/views/main/sponsorship/contents/sponsorView.vue'
              ),
          },
          {
            path: 'sponsorForm',
            name: 'sponsor_form',
            component: () =>
              import(
                /* webpackChunkName: "front_sponsor_apply" */ '@/views/main/sponsorship/contents/sponsorForm.vue'
              ),
          },
          {
            path: 'floorPlan',
            name: 'sponsor_floor',
            component: () =>
              import(
                /* webpackChunkName: "front_sponsor_floor" */ '@/views/main/sponsorship/contents/sponsorFloor.vue'
              ),
          },
        ],
      },
      {
        path: 'informations',
        name: 'informations',
        redirect: '/informations/news_notices',
        component: () =>
          import(/* webpackChunkName: "front_inform" */ '@/views/main/information/index.vue'),
        children: [
          {
            path: 'news_notices',
            name: 'info_news',
            component: () =>
              import(
                /* webpackChunkName: "front_inform_news" */ '@/views/main/information/contents/newsNotice.vue'
              ),
          },
          {
            path: 'general_information',
            name: 'info_general',
            component: () =>
              import(
                /* webpackChunkName: "front_inform_general" */ '@/views/main/information/contents/generalInfo.vue'
              ),
          },
          // {
          //   path: 'about_seoul',
          //   name: 'about_seoul',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "front_inform_about" */ '@/views/main/information/contents/aboutPlace.vue'
          //     ),
          // },
          {
            path: 'venue',
            name: 'info_venue',
            component: () =>
              import(
                /* webpackChunkName: "front_inform_venue" */ '@/views/main/information/contents/venue.vue'
              ),
          },
          // {
          //   path: 'gallery',
          //   name: 'info_gallery',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "front_inform_gallery" */ '@/views/main/information/contents/gallery.vue'
          //     ),
          // },
          {
            path: 'download_center',
            name: 'info_download',
            component: () =>
              import(
                /* webpackChunkName: "front_inform_download" */ '@/views/main/information/contents/downloadCenter.vue'
              ),
          },
          {
            path: 'bbsDetail/:bbs_id',
            name: 'bbs_detail',
            component: () =>
              import(
                /* webpackChunkName: "front_inform_bbs_detail" */ '@/views/main/information/contents/newsDetail.vue'
              ),
          },
          // {
          //   path: 'galleryDetail/:id',
          //   name: 'gallery_detail',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "front_inform_gallery_detail" */ '@/views/main/information/contents/galleryDetail.vue'
          //     ),
          // },
        ],
      },
    ],
  },
  { path: '/:pathMatch(.*)*', name: 'notfound', component: notFound },
  { path: '/block', name: 'blak', component: notAuth },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VUE_APP_BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

export default router
