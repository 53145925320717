<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="29.131"
    height="16.993"
    viewBox="0 0 29.131 16.993"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="사각형_449" data-name="사각형 449" width="29.131" height="16.993" fill="none" />
      </clipPath>
    </defs>
    <rect id="사각형_430" data-name="사각형 430" width="29.131" height="16.993" fill="#fff" />
    <rect
      id="사각형_431"
      data-name="사각형 431"
      width="0.708"
      height="1.947"
      transform="translate(18.689 3.194) rotate(-33.686)"
    />
    <rect
      id="사각형_432"
      data-name="사각형 432"
      width="0.708"
      height="1.947"
      transform="translate(19.966 5.109) rotate(-33.686)"
    />
    <rect
      id="사각형_433"
      data-name="사각형 433"
      width="0.708"
      height="4.248"
      transform="matrix(0.832, -0.555, 0.555, 0.832, 19.573, 2.605)"
    />
    <rect
      id="사각형_434"
      data-name="사각형 434"
      width="0.708"
      height="1.947"
      transform="translate(20.457 2.016) rotate(-33.686)"
    />
    <rect
      id="사각형_435"
      data-name="사각형 435"
      width="0.708"
      height="1.947"
      transform="translate(21.734 3.931) rotate(-33.686)"
    />
    <rect
      id="사각형_436"
      data-name="사각형 436"
      width="0.708"
      height="4.249"
      transform="translate(7.495 10.657) rotate(-33.689)"
    />
    <rect
      id="사각형_437"
      data-name="사각형 437"
      width="0.708"
      height="1.947"
      transform="matrix(0.832, -0.555, 0.555, 0.832, 6.612, 11.246)"
    />
    <rect
      id="사각형_438"
      data-name="사각형 438"
      width="0.708"
      height="1.947"
      transform="matrix(0.832, -0.555, 0.555, 0.832, 7.888, 13.16)"
    />
    <rect
      id="사각형_439"
      data-name="사각형 439"
      width="0.708"
      height="4.248"
      transform="translate(5.728 11.835) rotate(-33.689)"
    />
    <rect
      id="사각형_440"
      data-name="사각형 440"
      width="4.248"
      height="0.708"
      transform="translate(7.496 6.336) rotate(-56.311)"
    />
    <rect
      id="사각형_441"
      data-name="사각형 441"
      width="4.249"
      height="0.708"
      transform="translate(6.612 5.747) rotate(-56.311)"
    />
    <rect
      id="사각형_442"
      data-name="사각형 442"
      width="4.248"
      height="0.708"
      transform="translate(5.729 5.158) rotate(-56.316)"
    />
    <rect
      id="사각형_443"
      data-name="사각형 443"
      width="1.947"
      height="0.708"
      transform="matrix(0.555, -0.832, 0.832, 0.555, 18.689, 13.799)"
    />
    <rect
      id="사각형_444"
      data-name="사각형 444"
      width="1.947"
      height="0.708"
      transform="matrix(0.555, -0.832, 0.832, 0.555, 19.966, 11.884)"
    />
    <rect
      id="사각형_445"
      data-name="사각형 445"
      width="1.947"
      height="0.708"
      transform="matrix(0.555, -0.832, 0.832, 0.555, 19.573, 14.388)"
    />
    <rect
      id="사각형_446"
      data-name="사각형 446"
      width="1.947"
      height="0.708"
      transform="matrix(0.555, -0.832, 0.832, 0.555, 20.85, 12.473)"
    />
    <rect
      id="사각형_447"
      data-name="사각형 447"
      width="1.947"
      height="0.708"
      transform="matrix(0.555, -0.832, 0.832, 0.555, 20.457, 14.977)"
    />
    <rect
      id="사각형_448"
      data-name="사각형 448"
      width="1.947"
      height="0.708"
      transform="matrix(0.555, -0.832, 0.832, 0.555, 21.734, 13.062)"
    />
    <g id="그룹_1012" data-name="그룹 1012">
      <g id="그룹_1011" data-name="그룹 1011" clip-path="url(#clip-path)">
        <path
          id="패스_1096"
          data-name="패스 1096"
          d="M48.624,27.153c-.015.022-.031.045-.045.069.015-.023.03-.046.045-.069"
          transform="translate(-37.593 -21.013)"
          fill="#b50037"
        />
        <path
          id="패스_1097"
          data-name="패스 1097"
          d="M53.447,19.5a4.248,4.248,0,0,0-5.892,1.179,2.124,2.124,0,1,0,3.535,2.356,2.124,2.124,0,0,1,3.534,2.357A4.249,4.249,0,0,0,53.447,19.5"
          transform="translate(-36.524 -14.535)"
          fill="#b50037"
        />
        <path
          id="패스_1098"
          data-name="패스 1098"
          d="M46.334,27.153a2.124,2.124,0,0,0,3.535,2.356A2.124,2.124,0,1,1,53.4,31.866a4.248,4.248,0,1,1-7.069-4.713"
          transform="translate(-35.303 -21.013)"
          fill="#1e3476"
        />
      </g>
    </g>
  </svg>
</template>
