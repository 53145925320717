<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="59.391" height="46.19" viewBox="0 0 59.391 46.19">
    <path
      id="money-bill-wave-solid"
      d="M0,40.313V72.256a4.469,4.469,0,0,0,2.784,4.258c8.97,3.351,17.941,1.062,26.911-1.227,8.228-2.093,16.456-4.2,24.674-1.949,2.372.65,5.021-.98,5.021-3.444V37.962A4.469,4.469,0,0,0,56.607,33.7c-8.97-3.351-17.941-1.062-26.911,1.227-8.228,2.093-16.456,4.186-24.674,1.938C2.64,36.22,0,37.849,0,40.313ZM29.7,65.008c-4.557,0-8.249-4.434-8.249-9.9s3.691-9.9,8.249-9.9,8.249,4.434,8.249,9.9S34.253,65.008,29.7,65.008Zm-23.1,0a6.605,6.605,0,0,1,6.6,6.6H6.6Zm6.6-21.447a6.605,6.605,0,0,1-6.6,6.6v-6.6Zm39.594,16.5v6.6h-6.6A6.605,6.605,0,0,1,52.792,60.058Zm-6.6-21.447h6.6v6.6A6.605,6.605,0,0,1,46.193,38.612Z"
      transform="translate(0 -32.014)"
      fill="#111c58"
    />
  </svg>
</template>
