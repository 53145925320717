<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.464"
    height="20.112"
    viewBox="0 0 23.464 20.112"
  >
    <path
      id="bars-solid"
      d="M0,65.676A1.674,1.674,0,0,1,1.676,64H21.788a1.676,1.676,0,0,1,0,3.352H1.676A1.674,1.674,0,0,1,0,65.676Zm0,8.38A1.674,1.674,0,0,1,1.676,72.38H21.788a1.676,1.676,0,0,1,0,3.352H1.676A1.674,1.674,0,0,1,0,74.056Zm23.464,8.38a1.674,1.674,0,0,1-1.676,1.676H1.676a1.676,1.676,0,0,1,0-3.352H21.788A1.674,1.674,0,0,1,23.464,82.436Z"
      transform="translate(0 -64)"
      fill="#fff"
    />
  </svg>
</template>
