<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="59.391"
    height="79.188"
    viewBox="0 0 59.391 79.188"
  >
    <path
      id="plug-solid"
      d="M14.848,0A4.944,4.944,0,0,0,9.9,4.949V19.8h9.9V4.949A4.944,4.944,0,0,0,14.848,0Zm29.7,0a4.944,4.944,0,0,0-4.949,4.949V19.8h9.9V4.949A4.944,4.944,0,0,0,44.543,0ZM4.949,24.746a4.949,4.949,0,0,0,0,9.9v4.949a24.757,24.757,0,0,0,19.8,24.251V74.238a4.949,4.949,0,1,0,9.9,0V63.845a24.757,24.757,0,0,0,19.8-24.251V34.645a4.949,4.949,0,0,0,0-9.9Z"
      fill="#111c58"
    />
  </svg>
</template>
